<template>
  <div>
    <v-form
      @submit.prevent="login(form)">
      <v-card-text
        class="pa-5">
        <input-text
          class="mb-5"
          label="관리자 아이디"
          v-model="form.id">
        </input-text>
        <input-text
          label="비밀번호"
          type="password"
          v-model="form.password">
        </input-text>
      </v-card-text>
      <v-card-text
        class="login-card-btn-container">
        <v-btn
          type="submit"
          depressed
          large
          class="mb-4"
          color="primary"
          block>
          로그인
        </v-btn>
        <v-btn
          @click="$router.push('/welcome/reset/default')"
          color="secondary"
          outlined
          block>
          비밀번호 재설정
        </v-btn>
      </v-card-text>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import InputText from '@/components/input/InputText.vue';

export default {
  name: 'Login',
  components: {
    InputText,
  },
  data: () => ({
    form: {
      id: '',
      password: '',
    },
  }),
  methods: {
    ...mapActions({
      login: 'user/login',
    }),
  },
};
</script>

<style scoped>

</style>
