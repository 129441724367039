<template>
  <div class="input-container">
    <div class="input-container-text">
      <span class="ma-auto">
        {{ label }}
      </span>
    </div>
    <input
      v-model="inputValue"
      :type="type"
      :readonly="readonly"
      class="input-container-value">
  </div>
</template>

<script>
export default {
  name: 'InputText',
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  &-text {
    min-width: 120px;
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #323232;
    color: #eeeeee;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #323232;
    border-radius: .5rem 0 0 .5rem;
  }

  &-value {
    margin-left: -1px;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:focus {
      color: #212529;
      background-color: #fff;
      border-color: #ffffff;
      outline: 0;
      box-shadow: 0 0 0 .15rem rgba(0, 0, 0, 0.25);
    }
  }
}
</style>
