<template>
  <div>
    <v-form
      @submit.prevent="resetPassword(form)">
      <v-card-text
        class="pa-5">
        <input-text
          :readonly="id !== 'default'"
          class="mb-5"
          label="관리자 아이디"
          v-model="form.id">
        </input-text>
        <input-text
          v-if="id === 'default'"
          class="mb-5"
          label="기존 비밀번호"
          type="password"
          v-model="form.password">
        </input-text>
        <input-text
          class="mb-5"
          label="새 비밀번호"
          type="password"
          v-model="form.newPassword">
        </input-text>
        <input-text
          label="새 비밀번호 확인"
          type="password"
          v-model="rePassword">
        </input-text>
      </v-card-text>
      <v-card-text
        class="login-card-btn-container">
        <v-btn
          type="submit"
          depressed
          large
          class="mb-4"
          color="primary"
          block>
          재설정
        </v-btn>
        <v-btn
          @click="$router.push('/welcome')"
          color="secondary"
          outlined
          block>
          취소
        </v-btn>
      </v-card-text>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import InputText from '@/components/input/InputText.vue';

export default {
  name: 'Reset',
  components: {
    InputText,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    form: {
      id: '',
      password: '',
      newPassword: '',
    },
    rePassword: '',
  }),
  watch: {
    $route(to) {
      this.form = {
        id: '',
        password: '',
        newPassword: '',
      };
      this.rePassword = '';

      if (to.params.id !== 'default') {
        this.form.id = to.params.id;
      } else {
        this.form.id = '';
      }
    },
  },
  mounted() {
    if (this.id !== 'default') {
      this.form.id = this.id;
    }
  },
  methods: {
    ...mapActions({
      resetPassword: 'user/resetPassword',
    }),
  },
};
</script>

<style lang="scss" scoped>
a {
  background-color: #ffffff !important;
}
</style>
